<template>
    <v-card flat v-if="packages">
        <v-alert outline icon="info" color="success" :value="true" class="pt-2 pb-2" v-if="isStorePage">
            {{ $t('packageList.follow-use') }} {{ now() }} {{ $t('packageList.calculate-open') }}
        </v-alert>
        <v-card-text class="pa-0">
            <v-subheader class="subheading">
                <v-checkbox v-if="allowSelectAll" :label="$t('packageList.select-all')" v-model="selectAll" @click.stop="toggleSelectAll"></v-checkbox>
                <v-spacer></v-spacer>
                {{ $t('packageList.select-cell') }}
            </v-subheader>
            <v-list subheader three-line>
                <template v-for="(p, i) in packages">
                    <v-list-tile :key="i" avatar>
                        <v-list-tile-action class="small-action">
                            <v-checkbox v-model="selectTrackNos" :value="p.track_no"></v-checkbox>
                        </v-list-tile-action>
                        <v-list-tile-content @click="toggleSelect(p)">
                            <v-list-tile-title class="tile-title">
                                {{ $t('packageList.cell') }}&nbsp;{{ p.door_id }}
                                <v-chip small label dark :color="typeColor(p.type)">{{ typeName(p) }}</v-chip>
                            </v-list-tile-title>
                            <v-list-tile-sub-title>{{ $t('packageList.putin-time') }} {{ convertUnixtime(p.putin.time) }}</v-list-tile-sub-title>
                            <v-list-tile-sub-title v-if="p.type === 1">{{ p.note }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-action class="options-action">
                            <span class="green--text font-weight-medium">
                                <v-chip v-if="isReletable(p)" small label outline disabled color="warning">{{ options[p.track_no].title }}</v-chip>
                                {{ options[p.track_no].amount }} {{ $t('packageList.NTD') }}
                            </span>
                            <v-chip v-if="isReletable(p)"
                                small dark
                                color="error"
                                @click="showPlanPicker(p)"
                            >
                                {{ $t('packageList.other-plans') }}<v-icon>arrow_right</v-icon>
                            </v-chip>
                        </v-list-tile-action>
                    </v-list-tile>
                    <v-divider :key="p.track_no"></v-divider>
                </template>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-btn flat large block color="primary" @click="back">
                <v-icon>arrow_back_ios</v-icon>{{ $t('packageList.back') }}
            </v-btn>
            <v-btn block color="primary" class="subheading" :disabled="!isValid" @click="confirm">{{ $t('packageList.confirm') }}</v-btn>    
        </v-card-actions>
        <plan-picker-dialog
            :show.sync="showPicker"
            :package-info="pickerPackage"
            @chose="chosePlan"
        >
        </plan-picker-dialog>
    </v-card>
    <v-card v-else flat>
        <v-subheader class="text-xs-center subheading">{{ $t('packageList.no-cell-use') }}</v-subheader>
    </v-card>
</template>

<script>
import PlanPickerDialog, { PlanTitles } from '@/components/PlanPickerDialog'
import { formatDatetime } from '@/utils/utils'
import { TransTypes } from '@/mixins/PutinMixin'
import i18n from '@/i18n'

const TypesInfo = {
    [TransTypes.Delivery]: { name: i18n.t('packageList.receive'), color: 'purple' },
    [TransTypes.Leave]: { name: i18n.t('packageList.temporary-storage'), color: 'warning' },
    [TransTypes.LongLeave]: { name: i18n.t('packageList.monthly-plan'), color: 'success' },
    [TransTypes.StoreHost]: { name: i18n.t('packageList.goods-name'), color: 'purple'},
    [TransTypes.WashClient]: { name: i18n.t('packageList.wash-name'), color: 'purple'},
    [TransTypes.WashClerk]: { name: i18n.t('packageList.wash-name'), color: 'indigo'},
    [TransTypes.APIClient]: { name: i18n.t('packageList.api-name'), color: 'blue'}
}

export default {
    name: 'package-list',
    components: { PlanPickerDialog },
    props: {
        packages: {
            type: Array,
            required: true
        },
        allowSelectAll:{
            type: Boolean,
            required: false,
            default: true
        },
        voiceMsg:{
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            selectTrackNos: [],
            options: {},
            showPicker: false,
            pickerPackage: null
        }
    },
    computed: {
        isStorePage() {
            if (this.voiceMsg == false)
                return false
            else
                return true
        },
        isValid() {
            return this.selectTrackNos.length > 0
        },
        selectAll() {
            return this.packages.length === this.selectTrackNos.length
        }
    },
    watch: {
        packages (newVal, oldVal) {
            if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                this.options = {}
                this.selectTrackNos = []
                this.packages.forEach(p => {
                    let reletable = this.isReletable(p)
                    this.options[p.track_no] = {
                        title: reletable ? PlanTitles[p.type] : PlanTitles.default,
                        amount: reletable ? p.relet_amount : p.amount,
                        type: p.type
                    }
                    if(this.allowSelectAll){
                        this.selectTrackNos.push(p.track_no)
                    }
                })
            }
        }
    },
    methods: {
        isReletable(p) {
            return p.relet_amount !== null && p.type === TransTypes.LongLeave
        },
        showPlanPicker(p) {
            this.pickerPackage = p;
            this.showPicker = true;
        },
        chosePlan(trackNo, plan) {
            this.options[trackNo] = {
                title: plan.title,
                amount: plan.amount,
                type: plan.type
            }
        },
        toggleSelect(p) {
            let index = this.selectTrackNos.indexOf(p.track_no)
            if (index > -1) this.selectTrackNos.splice(index, 1)
            else this.selectTrackNos.push(p.track_no)
        },
        toggleSelectAll() {
            if (!this.selectAll) this.selectTrackNos = this.packages.map(p => p.track_no)
            else this.selectTrackNos = []
        },
        confirm() {
            let result = {}
            this.selectTrackNos.forEach(t => { result[t] = this.options[t].type })
            this.$emit('confirm', result)
        },
        back() {
            this.$emit('back')
        },
        now() {
            return formatDatetime(new Date(), false)
        },
        convertUnixtime(timestamp) {
            timestamp = timestamp.replace(/-/g, '/')
            return formatDatetime(new Date(timestamp), false)
        },
        typeName(p) {
            if (p.type != TransTypes.StoreHost)
                return TypesInfo[p.type].name
            else {
                if (!p.product_name)
                    return i18n.t('packageList.retrieve_good')
                else if (p.product_name.length > 8)
                    return p.product_name.substr(0,8)
                else
                    return p.product_name
            }
        },
        typeColor(typeVal) {
            return TypesInfo[typeVal].color || ''
        }
    }
}
</script>

<style scoped>
    .tile-title {
        height: 30px;
    }
    .small-action {
        min-width: 40px;
    }
    .options-action {
        justify-content: center;
    }
</style>